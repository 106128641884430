import React from "react";
import { EventProvider } from "../hooks";
import GlobalContextProvider from "../context/GlobalContextProvider";

type RootWrapperProps = Readonly<{
  element: React.ReactNode;
}>;

export const RootWrapper: React.FC<RootWrapperProps> = ({ element }) => {
  return (
    <GlobalContextProvider>
      <EventProvider>{element}</EventProvider>
    </GlobalContextProvider>
  );
};
