// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ja-js": () => import("./../../../src/pages/about.ja.js" /* webpackChunkName: "component---src-pages-about-ja-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-ja-js": () => import("./../../../src/pages/links.ja.js" /* webpackChunkName: "component---src-pages-links-ja-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-templates-report-detail-template-en-js": () => import("./../../../src/templates/ReportDetailTemplate.en.js" /* webpackChunkName: "component---src-templates-report-detail-template-en-js" */),
  "component---src-templates-report-detail-template-ja-js": () => import("./../../../src/templates/ReportDetailTemplate.ja.js" /* webpackChunkName: "component---src-templates-report-detail-template-ja-js" */)
}

