import React, { createContext, useContext, useState } from "react"
import { EventEmitter } from "events"

const EventContext = createContext<EventEmitter>(new EventEmitter())

export const useEvents = () => {
  return useContext(EventContext)
}

type EventProviderProps = Readonly<{
  children: React.ReactNode
}>

export const EventProvider: React.FC<EventProviderProps> = ({ children }) => {
  const [events] = useState<EventEmitter>(new EventEmitter())
  return (
    <EventContext.Provider value={events}>{children}</EventContext.Provider>
  )
}
